import React, { memo } from "react";
import { css, styled } from "goober";

import getHextechRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import { abbreviateRank } from "@/game-tft/utils.mjs";

const Tier = styled("p")`
  color: var(--shade2);
  margin-left: var(--sp-1_5);
`;

function Rank({ league }) {
  const TierIcon = getHextechRankIcon(league?.tier?.toLowerCase());
  return (
    <>
      <TierIcon
        className={css`
          width: var(--sp-4);
          height: var(--sp-4);
          margin-left: var(--sp-2);
        `}
      />
      <Tier className="type-caption">{abbreviateRank(league)}</Tier>
    </>
  );
}

export default memo(Rank);
