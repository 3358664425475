import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import {
  REGION_LIST,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import { LoadingContainer } from "@/game-lol/TierList.style.jsx";
import { getCurrentSet, setDates } from "@/game-tft/constants.mjs";
import ProBuildsMatch from "@/game-tft/ProBuildsMatch.jsx";
import tabs from "@/game-tft/SelectFilterTabs.jsx";
import {
  SelectFilter,
  SelectFilterDefaults,
  SelectRegion,
  // ToggleTop4Only,
} from "@/game-tft/Selects.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import useProBuilds from "@/game-tft/use-probuilds.mjs";
import LoadingSpinner from "@/inline-assets/loading-spinner.svg";
import { EmptyListProxy } from "@/shared/EmptyListProxy.jsx";
import { FlexContainer } from "@/shared/InfiniteTable.style.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import deepMerge from "@/util/deep-merge.mjs";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const RANGE_OF_SETS = 3; // Forward, present and previous
const all = ROLE_SYMBOL_TO_STR[ROLE_SYMBOLS.all].key;

function ProBuilds() {
  const state = useSnapshot(readState);
  const set = getCurrentSet();
  const champions = state.tft[set]?.champions;
  const _companions = state.tft.companions;
  const traits = useMemo(() => {
    // Deep merge the last 3 sets instead of all set data to reduce deep merging useless data
    const traits = {};
    for (let i = 0; i < RANGE_OF_SETS; i += 1) {
      const set = setDates[i].set;
      const traitsStatic = state.tft[set]?.traits;
      const [classes, origins] =
        StaticTFT.getClassAndOriginsFromTraits(traitsStatic);
      const a = classes;
      const b = origins;
      if (a) deepMerge(traits, { [set]: a });
      if (b) deepMerge(traits, { [set]: b });
    }
    return traits;
  }, [state.tft]);
  const { searchParams } = useRoute();
  const { t } = useTranslation();
  const isLoaded = useIsLoaded();
  const filters = useMemo(() => {
    const classes = searchParams.get("class") || all;
    const champion = searchParams.get("champion") || all;
    const _champions = searchParams.get("champions");
    const champions =
      typeof _champions === "string" && _champions.length
        ? _champions.split("+")
        : all;
    const _traits = searchParams.get("traits");
    const traits =
      typeof _traits === "string" && _traits.length ? _traits.split("+") : [];
    const origins = searchParams.get("origin") || all;
    const region = searchParams.get("region") || REGION_LIST[0].key;
    const top4Only = searchParams.get("top4Only") || false;
    const augments = searchParams.get("augments")
      ? searchParams.get("augments").split("+")
      : all;
    const items = searchParams.get("items")
      ? searchParams.get("items").split("+")
      : all;
    return {
      classes,
      champion,
      champions,
      traits,
      origins,
      region,
      top4Only,
      augments,
      items,
    };
  }, [searchParams]);
  const { allMatches, filteredMatches } = useProBuilds({ filters });

  const filterSelect = SelectFilterDefaults(searchParams);

  const companions = useMemo(
    () => (Array.isArray(_companions) ? _companions : []),
    [_companions],
  );
  const hasMatches = !!allMatches.length;
  const hasFilteredMatches = !!filteredMatches.length;
  const TableView = useMemo(() => {
    if (hasMatches && !hasFilteredMatches && isLoaded)
      return (
        <EmptyListProxy
          text={t("tft:noDataFound", "No games played of this game type.")}
          subtext={t("lol:anotherFilter", "Try another filter.")}
        />
      );

    if (!hasFilteredMatches) {
      return (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      );
    }

    return (
      <>
        {filteredMatches.map(({ game, match }, idx) => (
          <ProBuildsMatch
            key={idx}
            player={match}
            game={game}
            companions={companions}
            traits={traits}
            champions={champions}
          />
        ))}
      </>
    );
  }, [
    champions,
    companions,
    filteredMatches,
    hasFilteredMatches,
    hasMatches,
    isLoaded,
    t,
    traits,
  ]);

  return (
    <PageContainer>
      <PageHeader title={t("lol:probuilds", "Pro Builds")} />
      <div className={PageLayout()}>
        <ProBuildsFilterCard>
          <SelectFilter filters={filterSelect} tabs={tabs} allowed={"all"} />
          <SelectRegion />
          <ButtonGroup>
            <Button href="/tft/probuilds">{t("common:reset", "Reset")}</Button>
          </ButtonGroup>
          {/*<div className="pull-end">
            <ToggleTop4Only />
            </div>*/}
        </ProBuildsFilterCard>
        <FlexContainer>
          <Card>
            <ol>{TableView}</ol>
          </Card>
        </FlexContainer>
      </div>
    </PageContainer>
  );
}

export function meta(_, searchParams) {
  let set = searchParams.get("set") || getCurrentSet().replace("set", "");
  set = set.replace("_", ".");
  return {
    title: ["tft:meta.probuilds.title", "TFT Pro Builds"],
    description: [
      "tft:meta.probuilds.description",
      "View high-tier comps used by top pro TFT players in recent games.",
    ],
    subtitle: [
      "tft:meta.probuilds.subtitle",
      "Discover what Teamfight Tactics comps and builds the pros are using in the Set {{set}} meta.",
      {
        set,
      },
    ],
  };
}

export default ProBuilds;

const PageLayout = () => css`
  display: grid;
  gap: var(--sp-4);
`;
const ProBuildsFilterCard = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-3);
  flex-wrap: wrap;

  & > div {
    ${mobile} {
      flex: 1;

      & > button,
      & > a {
        width: 100%;
      }
    }
  }

  .pull-end {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }
`;
