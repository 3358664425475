import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { GameRecord, PlacementTitle } from "@/game-tft/CommonComponents.jsx";

function ProBuildsPlacementInfo({ placement, wins, losses }) {
  const { t } = useTranslation();
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
      `}
    >
      <PlacementTitle className="type-body2-form--active" place={placement}>
        {t("common:ordinalPlace", "{{place, ordinal}} Place", {
          place: placement,
        })}
      </PlacementTitle>
      {wins !== undefined && losses !== undefined ? (
        <GameRecord className="type-caption">
          {t("tft:winsAndLosses", "{{wins}}W - {{losses}}L", {
            wins,
            losses,
          })}
        </GameRecord>
      ) : null}
    </div>
  );
}

export default memo(ProBuildsPlacementInfo);
