import React, { useMemo } from "react";

import {
  companionDirectoryReplace,
  sortedRanks,
} from "@/game-tft/constants.mjs";
import ProBuildsMatchUnits from "@/game-tft/ProBuildsMatchUnits.jsx";
import ProBuildsTile from "@/game-tft/ProBuildsTile.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

export default function ProBuildsMatch({
  player, // incorrectly named
  game,
  // Static
  companions,
}) {
  const { searchParams } = useRoute();
  const matchSet = StaticTFT.getMatchSetByDate(
    game.createdAt,
    game.queue,
    searchParams,
  );
  const latestSet = StaticTFT.getMatchSetByDate(new Date().toString());
  const isOutOfDate = matchSet !== latestSet;
  const { createdAt, queueId, matchid, patch } = game;

  // literally pick a random summoner thats high rank. fk it
  const rankedParticipants = player.info.participants.filter(
    (a) => a.BLITZ_riot_account,
  );
  rankedParticipants.sort((a, b) => {
    const a_i = sortedRanks.indexOf(a.BLITZ_riot_account.league_tft?.[0]?.tier);
    const b_i = sortedRanks.indexOf(b.BLITZ_riot_account.league_tft?.[0]?.tier);
    return a_i - b_i;
  });

  const summoner = rankedParticipants[0];

  const {
    league_tft: ranks,
    summoner: { profile_icon_id: profileIconId },
    account,
  } = summoner.BLITZ_riot_account;
  const name = account.game_name + "-" + account.tag_line;
  const region = player.metadata.match_id.split("_")[0];
  const { placement } = summoner;
  const { wins, losses } = ranks?.[0] || {};

  const { traits, units } = summoner;
  const image = useMemo(() => {
    const companion = summoner.companion;
    const result =
      companions.find((i) => i.contentId === companion.content_ID) || {};
    return companionDirectoryReplace(result.loadoutsIcon);
  }, [companions, summoner.companion]);

  return (
    <ProBuildsTile
      matchid={matchid}
      placement={placement}
      name={name}
      region={region}
      ranks={ranks}
      wins={wins}
      losses={losses}
      profileIconId={profileIconId}
      queueId={queueId}
      patch={patch}
      createdAt={createdAt}
      companionImage={image}
      traits={traits}
      isOutOfDate={isOutOfDate}
      set={matchSet}
    >
      <ProBuildsMatchUnits units={units} set={matchSet} />
    </ProBuildsTile>
  );
}
