import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { TraitsText } from "@/game-tft/CommonComponents.jsx";
import fixTraitNames from "@/game-tft/get-trait-names.mjs";
import { translateTraits } from "@/game-tft/use-traits.mjs";
import countBy from "@/util/count-by.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function TraitsBuildDetails({ traits, set, max = 2 }) {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const setTraits = state.tft[set]?.traits;

  if (typeof traits[0]?.name === "string") {
    const sortedTraits = [...traits];
    orderArrayBy(
      sortedTraits,
      (trait) => {
        const result = trait.tier_current / trait.tier_total;
        if (!Number.isFinite(result) || Number.isNaN(result)) return 0;
        return result;
      },
      "desc",
    );
    const arr = [];
    for (let i = 0; i < sortedTraits.length; i += 1) {
      if (i >= max) break;
      const trait = sortedTraits[i];
      if (!trait) continue;
      const traitName = fixTraitNames(
        (trait.name.match(/(?:(?:se|tf)t(?:\d+.*_))?(.*)/) || [])[1],
      );
      if (typeof traitName !== "string") continue;
      const key = traitName.toLowerCase().replace(/[\s-]+/g, "");
      const selectedTrait = setTraits[key];
      if (selectedTrait) {
        arr.push(<span>{translateTraits(t, selectedTrait.name, set)}</span>);
      }
    }
    if (arr.length)
      return (
        <TraitsText className="type-caption">
          {arr.map((i, idx) => {
            return (
              <React.Fragment key={idx}>
                {i}
                {idx !== arr.length - 1 ? " / " : ""}
              </React.Fragment>
            );
          })}
        </TraitsText>
      );
  }
  if (typeof traits[0] === "string") {
    const sortedTraits = Object.entries(countBy(traits))
      .sort((l, r) => r[1].num_units - l[1].num_units)
      .map(([key, count], i) => {
        const lKey = fixTraitNames(key.toLowerCase());
        const trait = setTraits[lKey];
        if (!trait) return null;
        if (count >= trait.bonuses[0].needed && i < max) {
          return trait;
        }
        return null;
      });
    if (sortedTraits.length)
      return <TraitsBuildDetails traits={sortedTraits} set={set} />;
  }
  return null;
}

export default memo(TraitsBuildDetails);
